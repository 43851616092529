import React from 'react';
import './Card.css';
import achiv1 from '../../../images/achiv1.png'
import achiv2 from '../../../images/achiv2.png'
import achiv3 from '../../../images/achiv3.png'

const Card = ({ imageSrc, title, description }) => {
  return (
    <div className="card">
      <img src={imageSrc} alt={title} className="card-image" />
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </div>
  );
};

const CardContainer = () => {
  const cards = [
    {
      imageSrc: achiv1,
      title: 'Digital Skills: User Experience',
      description: 'Our team is strengthened by a member with a deep understanding of user behavior and needs.'
    },
    {
      imageSrc: achiv2,
      title: 'Google Digital Marketing & E-commerce',
      description: 'Our team includes a digital marketing professional skilled in turning data into actionable insights. '
    },
    {
      imageSrc: achiv3,
      title: 'Google Ads Video Certification',
      description: 'Our team member specializes in harnessing the power of video advertising to achieve your marketing goals'
    },
  ];

  return (
    <div className="card-container" data-aos="zoom-in-up"
    data-aos-duration="1300">
      {cards.map((card, index) => (
        <Card
          key={index}
          imageSrc={card.imageSrc}
          title={card.title}
          description={card.description}
        
        />
      ))}
    </div>
  );
};

export default CardContainer;
