import React, { useEffect } from "react";
import blog1 from "../../../images/blog4.png";
import beer2 from "../../../images/beer-2.png";
import tony from "../../../images/tony.png";

import "../Bloge/Blogb.css";
import bg1 from "../../../images/bg-text1.svg";
import bg2 from "../../../images/bg-text2.svg";
import FloatingLabelInput from "../../Page1/Placeholder/Place";
import Footere from "../../Page1/Footer/Footer";

const Blogbe3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container-fluid blogb-main">
      <div className="row">
        <div className="col-8 ">
          <div className="beer1-head">
            <img className="beer1" src={blog1} alt="" />
          </div>
        </div>
        <div className="col-3 beer2-head">
          <div className="tony-headb">
            <div>
              <img className="tonyb" src={tony} alt="" />
            </div>
            <div className="tony-textb ">
              <h6>Kriya & Reeths</h6>
              <p>Coimbatore</p>
            </div>
          </div>
          <img className="beer2" src={blog1} alt="" />
        </div>
      </div>

      <div className="blogb-head">
        <div className="blogb-head-sub">
          <h6>Digital Marketing</h6>
          <h4 className="mt-2">Significance of 📲Online Marketing🌐💻</h4>
          <p className="mt-3">
          The term online marketing refers to marketing conducted exclusively on the Internet with mobile phones, and laptops by this we can integrate techniques and strategies for promoting products and services via platforms like websites, email, social media and other online channels. This online marketing will not be connected to traditional or offline marketing.some significance of online marketing is,
          </p>
          <div className="mt-5"></div>
         
          <p className="sub-head-par">
          1. PERSONALISATION:
          </p>
          <p>
          Today's consumers look to the internet to research products before making a purchase; 87% of purchases start with an online search.
Personalised messages with customers always influence them to open or engage the mail.
MailChimp tool will help to make your conversation personalised.

          </p>
          <p className="sub-head-par">
          2. E-MARKET:
          </p>
          <p>
          One of the requirements for internet marketing is an electronic mall, which has all the brands and varieties of stores we could ever want.
This site makes it easy to find and buy the brand and product we need.
There are 4types of E-Mall  stores General E-Mall, Specialised E-Mall, Regional E-Mall and pure online store

          </p>

          <p className="sub-head-par">
          3. Saviour of unconditional plans:
          </p>
          <p>
          The current online marketing flourished only after COVID-19 during this pandemic situation world went into lockdown and many businesses, including retail stores suffered majorly.  
Social media online marketing will serve as the best way to purchase a needy product.
online marketing is the only place to remember brands behind customers.




          </p>

          <p className="sub-head-par">
          4. SWOT Analysis:

          </p>
          <p>
          A study was undertaken by an organization to identify its internal strengths and weaknesses, as well as its external opportunities and threats.
Online marketing helps a lot in analysing a business, Google have many tools like Google Analytics, Facebook Pixel etc. 




          </p>

          <p className="sub-head-par">
          📱Go with Trends:
          </p>
          <p>
          Everyone has a personal account in social media is becoming common now this is replacing a newspaper.
By this, we can easily learn what are the trends happening in the industry.
Hashtags #, phrases associated with the industry will help to display the ad at the top of Funnel.
Collaboration with influencers and affiliate marketing is also one of the trends to acquire the full benefits of social media.
Top brands like Starbucks, Nike, and Coca-Cola follow this analytics for their brand online.


          </p>
          <p className="sub-head-par">
          5. Drives Quality Traffic:
          </p>
          <p>
          Traditional advertisements will target only the prime locations and uninterested people where your ad is displayed leading to low-quality traffic.
In terms of online campaigns, we can target a prominent audience and generate quality traffic to the website.
For example, when running a Pay Per Click campaign on Facebook and Google you are guaranteed to be targeting certain interest demographics such as income, age group, location etc.




          </p>

          
          <div className="pb-5"></div>




        </div>
      </div>

      <div className="section6 mt-5">
        <div className="bg1-cont">
          <img className="bg-txt11" src={bg1} alt="" />
        </div>
        <div className="community-head">
          <div className="community-hd1">
            <h1 className="hd1-two">
              <span className="hd1-one ">Join Our</span>Community
            </h1>
          </div>
          <p>
          Give us a call. Our Door is always open!
          </p>
          <div className="float-input">
            <FloatingLabelInput />
          </div>
          <div className="com-but">Submit</div>
        </div>
        <div className="bg2-cont">
          <img className="bg-txt2" src={bg2} alt="" />
        </div>
      </div>
      <Footere/>
    </div>
  );
};

export default Blogbe3;
